import {React, useState, useEffect} from 'react';
import { Table , useAPI, Card as CardCustom} from 'components/lib';
import Axios from 'axios';
import { apiKeyNames } from '../../helper/enum';
import { Card, CardContent, Typography, Box, Button, IconButton, Avatar, Chip, useMediaQuery, Grid, Code, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CodeIcon from '@mui/icons-material/Code';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme, createTheme, responsiveFontSizes } from '@mui/material/styles';
import styled from '@emotion/styled';
import videoSrc from './build_your_own_algo.mp4';
import { Event } from 'components/lib';

const _ = require('lodash');

let theme = createTheme();
theme = responsiveFontSizes(theme);

const StyledCard = styled(Card)({
  maxWidth: '99%',
  borderRadius: '10px',
  padding: '20px',
  backgroundColor: '#e2e7ef',
  position: 'relative',
  margin: 'auto',
  marhginTop: '20px',
  marginBottom: '20px',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.2)',
  background: '#e2e7ef',
  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  },
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
});

export const Header = styled(Typography)({
  fontSize: '15px',
  fontWeight: 'bold',
  marginBottom: '3px',
  color: '#000',
  paddingLeft: '20px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '6px',
    fontSize: '11px',
  },
});

export const SubHeader = styled(Typography)({
  fontSize: '14px',
  color: '#555',
  marginBottom: '3px',
  paddingLeft: '20px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '10px',
    fontSize: '9px',
  },
});

const VideoContainer = styled(Box)({
  width: '80%',
  height: '300px',
  backgroundColor: '#000',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  marginBottom: '16px',
  borderRadius: '5px',
  borderColor: '#E01296',
  borderWidth: '2px',
  marginTop: '5px',
  [theme.breakpoints.down('sm', 'md')]: {
    height: '150px',
    width: '100%',
  },
});

export const Link = styled(Typography)({
  fontSize: '14px',
  color: '#E01296',
  cursor: 'pointer',
  textDecoration: 'underline',
  marginLeft: '4px',
  marginRight: '4px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '9px',
    marginLeft: '2px',
    marginRight: '2px',
  },
});

const StyledVideo = styled('video')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });
  

const InfoBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

const InfoSection = styled(Box)({
  flex: '1',
  padding: '16px',
});

const Highlight = styled(Typography)({
  color: '#E01296',
  cursor: 'pointer',
  textDecoration: 'underline',
});

export const GetStarted = ({ darkMode }) => {
    const fetchKeys = useAPI('/api/key');
    const [open, setOpen] = useState(true);
    const [keys, setKeys] = useState([]);
    const [testAPIKey, setTestAPIKey] = useState([]);
    const [hiddenkeys, sethiddenkeys] = useState([]);
    const [eyeIcon, setEyeIcon] = useState('eye');
    const [openVideoModal, setOpenVideoModal] = useState(false);

    const handleOpenVideoModal = () => {
      Event('dashboard_view_demo');
      setOpenVideoModal(true);
    }
    const handleCloseVideoModal = () => setOpenVideoModal(false);

    useEffect(() => {
        async function getTestAPIKey(activeKey) {
          // reveal the api key
          const data = activeKey[0];
          const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
          const state = _.cloneDeep(activeKey);
          state[state.findIndex(x => x.id === data.id)].key = key;
          setTestAPIKey(state);
        }
    
        if (fetchKeys.data && fetchKeys?.data?.length > 0){
          let activeKey = [];
          if(fetchKeys.data.find((k) => k.name === apiKeyNames.DEFAULT)){
            activeKey.push(fetchKeys.data.find((k) => k.name === apiKeyNames.DEFAULT));
          }else if(fetchKeys.data.find((k) => k.active === true)){
            activeKey.push(fetchKeys.data.find((k) => k.active === true));
          }
          const hidden = _.cloneDeep(activeKey);
          setKeys(activeKey);
          sethiddenkeys(hidden);
          getTestAPIKey(activeKey);
        }
    
      }, [fetchKeys]);

      async function reveal(data) {

        // reveal the api key
        if (eyeIcon === 'eye') {
          const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
          const state = _.cloneDeep(keys);
          state[state.findIndex(x => x.id === data.id)].key = key;
          setKeys(state);
          setEyeIcon('eye-off');
        } else {
          // hide the api key
          if (hiddenkeys.length > 0) {
            setKeys(hiddenkeys);
          }
          setEyeIcon('eye');
        }
    
      }
      
    
      const copyKey = async (data) => {
    
        let keyVal = '';
        if (data?.id) {
          keyVal = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
        }
        try {
          navigator.clipboard.writeText(keyVal);
        }
        catch (e) {
          console.error(e);
        }
      }
    
    theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleStartClick = () => {
        Event('dashboard_start');
        window.open(`https://playground.mbd.xyz/`, '_blank');
      };
    
      const handleViewDocsClick = () => {
        Event('dashboard_view_docs');
        window.open('https://docs.mbd.xyz', '_blank');
      };
      const sendViewDocsEvent = () => {
        Event('view_docs');
      };

    const handleClose = () => {
        setOpen(false);
      };
    
    if (!open) return null;


  return (
    <>
    <SubHeader>
    <span style={{ display: 'flex'}}>
      Browse all the ways to use us either with our  <Link onClick={handleStartClick}>no code app</Link> or <Link onClick={handleViewDocsClick}>developer docs</Link>
      </span>
    </SubHeader>
    <br/>
    <StyledCard style = {{backgroundColor : darkMode ? '#1e293b' : '#ffffff'}}>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <CardContent>
      <Typography variant={isMobile ? "h6" : "h5"} component="div" style={{ fontWeight: 'bold', marginBottom: '16px', color : darkMode ? "#fff" : "#000"}}>
          Get Started with 'mbd
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Chip
              label="No code app"
              variant="outlined"
              style={{
                borderColor: '#E01296',
                color: '#E01296',
                marginBottom: '8px',
              }}
              avatar={<FiberManualRecordIcon style={{ color: '#E01296' }} />}
            />
            {/* <br /> */}
            {/* <Typography variant="body1" component="div" style={{ fontWeight: 'bold', marginTop: '10px' }}>
            Explore hyper-personalized Feed Recipes, Build your own Algo - “build hyper personalized and safer feeds!"
            </Typography> */}
            <Highlight onClick={handleStartClick} style={{paddingLeft: '5px', paddingTop: '15px'}}>Start →</Highlight>
            {/* <br /> */}
            <Highlight onClick={handleOpenVideoModal} style={{ cursor: 'pointer', color: '#E01296', paddingLeft: '5px', paddingTop: '15px' }}>
                Product Demo →
            </Highlight>
            {/* <VideoContainer>
            <StyledVideo controls loop="true" autoplay="autoplay" muted id = 'vid'>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </StyledVideo>
            </VideoContainer> */}
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingLeft: isMobile ? '30px': '150px', alignItems: 'center'}}>
            {/* <Button
              variant="contained"
              style={{ backgroundColor: 'none', color: '#E01296', marginBottom: '8px', textTransform: 'none',  borderColor: '#E01296', borderWidth: '2px'}}
              startIcon={<CodeIcon style={{ color: '#E01296'}} />}
            >
              For Developers
            </Button> */}

            <Chip
              label="For Developers"
              variant="outlined"
              style={{
                borderColor: '#E01296',
                color: '#E01296',
                marginBottom: '8px',
              }}
              avatar={<CodeIcon style={{ color: '#E01296' }} />}
            />
            <Typography variant="body1" component="div" style={{ fontWeight: 'bold', marginTop: '10px', color : darkMode ? "#fff" : "#000"}}>
            Your default API key
            </Typography>
          <div style={{ justifyContent: 'space-between', height: 'fit-content'}}>
          <div style={{overflow:'hidden', paddingTop: '2%', paddingBottom: '1%', paddingRight: '1%', paddingLeft: '1%', overflowWrap: 'anywhere', width:'100%', textWrap:'wrap', color : darkMode ? "#fff" : "#000"}} >
          <Table
            data={keys}
            loading={fetchKeys.loading}
            show={['name','key']}
            tableStyle='fit-content border-separate -mb-6 mt-[-1.3em]'
            removeHeader={true}
            actions={{
              custom: [
                { icon: eyeIcon, action: reveal },
                { icon: 'clipboard', action: copyKey }
              ],
            }}
          />
          </div>
        </div>
            <Highlight onClick={handleViewDocsClick} style={{paddingLeft: '5px'}}>View Docs →</Highlight>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
    <Modal open={openVideoModal} onClose={handleCloseVideoModal} aria-labelledby="video-modal" aria-describedby="video-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 2,
          maxWidth: 600,
          width: '90%',
          outline: 'none',
          borderRadius: '12px'
        }}
      >
        <CloseButton onClick={handleCloseVideoModal} style={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </CloseButton>
        <Typography id="video-description" variant="h6" component="h2" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
          Product Demo
        </Typography>
        <video controls autoPlay muted style={{ width: '100%', borderRadius: '8px' }}>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Modal>

    </>
  );
};

// export default GetStarted;
