/***
*
*   API Keys List
*   List & manage the API keys
*
**********/

import React, { Fragment, useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import { ViewContext, Animate, AccountNav, Button, Card, Table, TitleRow,
  BlankSlateMessage, Loader, useNavigate, useAPI, Event } from 'components/lib';
import {apiKeyNames} from '../../../helper/enum';
const _ = require('lodash');

export function APIKeyList(props){

  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const fetch = useAPI('/api/key');
  const [keys, setKeys] = useState([]);
  const [hiddenkeys, sethiddenkeys] = useState([]);
  const [eyeIcon, setEyeIcon] = useState('eye');
  // const [defaultKey, setDefaultKey] = useState([]);

  useEffect(() => {

    if (fetch?.data?.length){
      const keysData = fetch?.data;
      setKeys(keysData);
      const hidden = _.cloneDeep(keysData);
      sethiddenkeys(hidden);
    }
      
  }, [fetch]);

  function revoke(data){
    context.modal.show({
      title: 'Revoke API Key ',
      form: {
        active: {
          type: 'hidden',
          value: false,
        }
      },
      text: 'Are you sure you want to revoke this API Key? This action cannot be undone.',
      buttonText: 'Revoke',
      url: `/api/key/${data.id}`,
      destructive: true,
      method: 'PATCH',

      }, () => {

        const state = [...keys];
        state.find(x => x.id === data.id).active = false;
        setKeys(state);

    });
  }

  function deleteKey(data, callback){

    const multi = Array.isArray(data);
    const id = multi ? data.map(x => { return x.id }) : data.id;

    Event('account_api_keys_click_delete_key');
    
    context.modal.show({
      title: `Delete API ${multi ? 'Keys' : 'Key'}`,
      text: `Are you sure you want to delete 
        ${multi ? 'these keys?' : 'this API Key?'} This action cannot be undone.`,
      buttonText: 'Delete',
      form: { 
        id: {
          type: 'hidden',
          value: id,
        }
      },
      url: '/api/key',
      destructive: true,
      method: 'DELETE',

      }, () => {
        callback();
        window.location.reload();

    });
  }

  async function reveal(data) {

    // reveal the api key
      const key = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
      const state = _.cloneDeep(keys);
      state[state.findIndex(x => x.id === data.id)].key = key;
      setKeys(state);
      setEyeIcon('eye-off');

  }

  async function hide(data) {
      // hide the api key
      if (hiddenkeys.length > 0) {
        const hiddenState = _.cloneDeep(hiddenkeys);
        const hiddenKey = hiddenState[hiddenState.findIndex(x => x.id === data.id)].key;
        const state = _.cloneDeep(keys);
        state[state.findIndex(x => x.id === data.id)].key = hiddenKey;
        setKeys(state);
      }
      setEyeIcon('eye');

  }

  const copyKey = async (data) => {

    let keyVal = '';
    if (data?.id) {
      keyVal = (await Axios.get(`/api/key/${data.id}`))?.data?.data?.[0].key;
    }
    try {
      navigator.clipboard.writeText(keyVal);
    }
    catch (e) {
      console.error(e);
    }
  }
    
  return (
    <Fragment>

      <AccountNav/>

      { fetch.loading ? 
        <Loader /> :
        <Fragment>
          { keys?.length ?
            <Animate>

            <TitleRow title='Your API Keys'>
              <Button small text='Generate New API Key' goto='/account/apikeys/create' event='account_api_keys_click_generate_new_key'/>
            </TitleRow>

            <Card>
              <Table 
                selectable
                search
                data={ keys } 
                loading={ fetch.loading }
                show={['name', 'key', 'active']}
                actions={{ 
                  custom: [
                    { icon: 'eye', action: reveal },
                    { icon: 'eye-off', action: hide },
                    { icon: 'clipboard', action: copyKey }
                    // { icon: 'rotate-ccw', action: revoke, condition: { col: 'active', value: true }}
                  ],
                  // edit: (data) => { navigate(`/account/apikeys/edit?id=${data.id}`) },
                  delete: deleteKey,
                }}
                bulkActions={{

                  delete: deleteKey,

                }}
                badge={{ col: 'active', color: 'green', condition: [

                  { value: true, color: 'green' },
                  { value: false, color: 'red' }
                
                ]}}
              />
            </Card> 
          </Animate> :
          <Animate>
            <br />
          <BlankSlateMessage 
            title='Create an API Key'
            text={`You haven't created any API keys yet. Would you like to create one now?`}
            buttonText='Create API Key'
            action={ () => navigate('/account/apikeys/create') }
            marginTop='6em'
            marginLeft='-12em'
          />
          </Animate>
          }
        </Fragment>
      }
    </Fragment>
  );
}
