import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, TitleRow, Event } from 'components/lib';
import { Box, Grid, TextField, Button, Checkbox, IconButton, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Axios from 'axios';
import { Loader } from 'components/lib'; // Import the Loader component
import { PageIntro } from 'mbd-components/page-intro';
import { Text } from 'mbd-components/text';
import { PrimaryButton } from 'mbd-components/primary-button';  

export function Moderation() {
    const [tabValue, setTabValue] = useState('items'); // 'items' or 'users'
    const [inputValue, setInputValue] = useState('');
    const [list, setList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); // To track selected items
    const [loading, setLoading] = useState(false); // To track loading state

    const authContext = useContext(AuthContext);

    // Fetch data when the component mounts or when tabValue changes (Items/Users toggle)
    useEffect(() => {
        setInputValue(''); // Clear input when tab changes
        fetchList(tabValue);
        setSelectedItems([]); // Reset selected items when switching between tabs
    }, [tabValue]);

    const fetchItemDetails = async (value) => {
        try {
            setLoading(true);
            const response = await Axios({
                url: '/api/farcaster/cast',
                method: 'get',
                params: { identifier: value, type: 'hash' },
            });

            const warpCastPostLink = response?.data?.data?.warpCastPostLink || null;

            setLoading(false);
            return warpCastPostLink || null;
        } catch (error) {
            console.error('Error fetching item details', error);
            setLoading(false);
            return null;
        }
    };

    const fetchUserDetailsByFid = async (value) => {
        try {
            setLoading(true);
            const response = await Axios({
                url: '/api/farcaster/user/bulk',
                method: 'get',
                params: { fids: value },
            });

            const username = response?.data?.data && response?.data?.data?.length > 0 ? response?.data?.data[0]?.username : null;
            const warpCastProfileLink = `https://warpcast.com/${username}`;

            setLoading(false);
            return warpCastProfileLink || null;
        } catch (error) {
            console.error('Error fetching user details', error);
            setLoading(false);
            return null;
        }
    };

    const fetchList = async (type) => {
        setList([]);
        setLoading(true);
        let responseListName = type === 'items' ? 'item_ids' : 'user_ids';
        try {
            // Fetch the latest data based on the current toggle (items or users)
            const response = await Axios({
                url: `/api/moderation/${type}`,
                method: 'get',
                params: { console_account_id: authContext?.user?.account_id || null },
            });

            // Check and set the list
            if (response?.data?.data && response?.data?.data?.[responseListName] && response?.data?.data?.[responseListName].length > 0) {
                setList(response?.data?.data?.[responseListName].map((val) => ({ id: val, value: val })));
            } else {
                setList([]); // Empty list if no data found
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching list', error);
            setLoading(false);
        }
    };

    const handleAddToExcludeList = async () => {
        if (!inputValue) return;

        try {
            setLoading(true); // Show loader
            // Update the list locally by adding the input value
            const updatedList = [...list, { id: inputValue, value: inputValue }];
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                Event('exclude_item_added');
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                Event('exclude_user_added');
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setInputValue(''); // Clear input after adding
            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error adding to exclude list', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleDeleteSelected = async (id) => {
        try {
            setLoading(true); // Show loader
            // Filter out the selected item locally
            const updatedList = list.filter(item => item.id !== id);
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                Event('exclude_item_deleted');
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                Event('exclude_user_deleted');
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting item', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleDeleteSelection = async () => {
        try {
            setLoading(true); // Show loader
            // Filter out the selected items locally
            const updatedList = list.filter(item => !selectedItems.includes(item.id));
            setList(updatedList);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                Event('exclude_items_delete_selected_items');
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: updatedList.map(item => item.value),
                };
            } else {
                Event('exclude_users_delete_selected_users');
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: updatedList.map(user => user.value),
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            // Clear selected items after deletion
            setSelectedItems([]);
            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting selected items', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleSelectItem = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleDeleteAll = async () => {
        try {
            setLoading(true); // Show loader
            // Clear the list locally
            setList([]);

            let data = {};
            let url = '';

            if (tabValue === 'items') {
                Event('exclude_items_delete_all_items');
                url = '/api/moderation/items/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    item_ids: [],
                };
            } else {
                Event('exclude_users_delete_all_users');
                url = '/api/moderation/users/upsert';
                data = {
                    console_account_id: authContext?.user?.account_id || null,
                    user_ids: [],
                };
            }

            // Make API request to update the backend
            await Axios({
                url: url,
                method: 'post',
                data: data,
            });

            setLoading(false); // Hide loader
        } catch (error) {
            console.error('Error deleting all items', error);
            setLoading(false); // Hide loader on error
        }
    };

    const handleView = async (value) => {
        let urlToOpen = null;
        if (tabValue === 'items') {
            urlToOpen = await fetchItemDetails(value);
        } else {
            urlToOpen = await fetchUserDetailsByFid(value);
        }
        
        if (urlToOpen) {
            window.open(urlToOpen, '_blank');
        } else {
            console.error('No URL to open');
        }
    };

    const handlePreview = async () => {
        let urlToOpen = null;
        if (tabValue === 'items') {
            urlToOpen = await fetchItemDetails(inputValue);
        } else {
            urlToOpen = await fetchUserDetailsByFid(inputValue);
        }

        if (urlToOpen) {
            window.open(urlToOpen, '_blank');
        } else {
            console.error('No URL to open');
        }
    };

    return (
        <Grid container justifyContent="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={10} md={8}>
                {loading ? (
                    <Loader /> 
                ) : (
                    <>

                        <PageIntro 
                            title="Exclude Items and Users" 
                            intro="Use this page to manage the lists for excluding items and users for your organization. Items and users added on this page will apply to all feeds for your organization." />

                        <Box sx={{ backgroundColor: '#fff', p: 5, borderRadius: 2 }}>

                            {/* ToggleButtonGroup for toggling between Items and Users - moved to lower section and aligned right */}
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <ToggleButtonGroup
                                    value={tabValue}
                                    exclusive
                                    onChange={(e, newValue) => setTabValue(newValue)}
                                    aria-label="items and users toggle"
                                >
                                    <ToggleButton value="items" aria-label="items">
                                        Items
                                    </ToggleButton>
                                    <ToggleButton value="users" aria-label="users">
                                        Users
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            <br />

                            {/* Input and Actions - aligned responsively */}

                            <Text variant='fieldHeading' color='brand-light'>
                                Add {tabValue === 'items' ? 'items' : 'users'} to global exclude list
                            </Text>
                            <Box sx={{ mt: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center' }}>
                                <TextField
                                    label={tabValue === 'items' ? 'Item ID' : 'User ID'}
                                    variant="outlined"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    sx={{ flexGrow: 1}} 

                                />
                                <Button onClick={handlePreview} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 }, color: '#d00e83' }}>
                                    Preview
                                </Button>
                                <PrimaryButton
                                    onClick={handleAddToExcludeList}
                                >
                                    Add to List
                                </PrimaryButton>
                            </Box>

                            {/* List of Items/Users */}
                            <Box sx={{ mt: 4, overflowX: 'auto' }}>
                                {list.length > 0 ? (
                                    list.map((item) => (
                                        <Box
                                            key={item.id}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', md: 'row' },
                                                alignItems: { xs: 'flex-start', md: 'center' },
                                                justifyContent: 'space-between',
                                                mb: 1,
                                                flexWrap: 'wrap',
                                                width: '100%' // Ensure items don't overflow horizontally
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
                                                <Checkbox
                                                    checked={selectedItems.includes(item.id)}
                                                    onChange={() => handleSelectItem(item.id)}
                                                />
                                                <Typography sx={{ wordBreak: 'break-word' }}>{item.id}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'flex-end', mt: { xs: 1, md: 0 } }}>
                                                <Button
                                                    onClick={() => handleView(item.id)}
                                                    sx={{ color: '#d00e83', mr: { md: 2, xs: 0 }, mb: { xs: 1, md: 0 } }}
                                                >
                                                    View
                                                </Button>
                                                <IconButton onClick={() => handleDeleteSelected(item.id)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="textSecondary" sx={{ml: 1}}>No {tabValue === 'items' ? 'items' : 'users'} in the list</Typography>
                                )}
                            </Box>

                            {/* Delete All or Delete Selection Button */}
                            {list.length > 0 && (
                                <PrimaryButton
                                    onClick={selectedItems.length > 0 ? handleDeleteSelection : handleDeleteAll}
                                >
                                    {selectedItems.length > 0 ? 'Delete Selection' : 'Delete All'}
                                </PrimaryButton>
                            )}
                        </Box>
                    </>
                )}
            </Grid>
        </Grid>
    );
}